$(function(){
  $(".fileuplaodcv").AjaxFileUpload({
    onChange: function(filename){
      // $(".btn-file").addClass("uploading");
      $(this).parents(".btn-file").addClass("uploading");
    },
    onComplete: function(filename, response) {
      




        var new_file_name = 'web/files/cv/'+filename;

        // $(".fileuplaodcvupdate").val(response)
        // $(".txt_up").text(filename);
        // $(".btn-file").removeClass("uploading");
        $(this).next().val(new_file_name);
        if(response=='ERROR'){
          $(this).prev().text('שגיאה');
          $(this).closest('.form-group').find('.help-block').text('שגיאה');
          $(this).closest('.form-group').addClass('has-error');
          $(this).next().val('');
        }else{
          $(this).closest('.form-group').removeClass('has-error');
          $(this).prev().text(filename);
        }
        $(this).parents(".btn-file").removeClass("uploading");
      }
  });
















  $('.input_upload_file_extra_1').on('change', function() {
    var fileName = $(this).val().split('\\').pop();
    var fileInput = this;
    if (fileInput.files.length > 0) {
      $(this).closest('.form-group').find('.txt_up').html('<i class="fa fa-check"></i>' + fileName);
        var fileSize = fileInput.files[0].size;
        console.log(fileSize);
        console.log('File size: ' + formatFileSize(fileSize));


        $(this).closest('.form-group').find('.txt_up').html('<i class="fa fa-check"></i><span class="size_wrap">'+formatFileSize(fileSize)+'</span>' + fileName);
        $(this).attr('data-size',fileSize);
        $(this).data('size',fileSize);

        total_size_calc();


    } else {
      var original_text =  $(this).closest('.form-group').find('.txt_up').attr('data-original-text');
      console.log('original_text')
      console.log(original_text)
      $(this).closest('.form-group').find('.txt_up').html(original_text);
      $(this).attr('data-size',0);
      $(this).data('size',0);
        console.log('No file selected');
        total_size_calc();
    }
});
function total_size_calc(){
  var totalSize = 0;
  $('.input_upload_file_extra_1').each(function() {
      let dataSize = $(this).data('size');
      if(dataSize){
      console.log(dataSize)
      totalSize += parseInt(dataSize, 10);
      }
  });
  console.log('Total :', totalSize);
  
  
  if (totalSize>20971520){
    console.log('too heavy!!!!!')

    $('.input_upload_file_extra_1').each(function() {
      $(this).closest('.form-group').find('.help-block').html('סך כל הקבצים גדול מ 20 MB');      
      $(this).closest('.form-group').addClass('has-error2');
      $(this).closest('.form-group').addClass('fileuploadhas-error2');
      $(this).closest('form').find('button.btn-form').prop('disabled', true);
    });



  }
  else{
    $('.input_upload_file_extra_1').each(function() {
      $(this).closest('.form-group').find('.help-block').html('');      
      $(this).closest('.form-group').removeClass('has-error2');
      $(this).closest('.form-group').removeClass('fileuploadhas-error2');
      $(this).closest('form').find('button.btn-form').prop('disabled', false);
    });
  }
}


function formatFileSize(bytes) {
  if (bytes === 0) return '0 Bytes';
  var k = 1024;
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  var i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}




















    $('button.btn.btn-block.btn-form').closest('form').on('submit', function() {
      $('#loaderSubmit').show();
    });










  
});



